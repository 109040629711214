<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>安全员管理</h5>
				<div class="formgroup-inline">
					<Button label="新增" icon="pi pi-plus" class="mr-6" @click="onAddSafer('add', '')" />
					<div class="field">
						<label for="adminCode" class="p-sr-only">关键字查询</label>
						<InputText id="adminCode" type="text" v-model="searchContent" placeholder="关键字查询" />
					</div>
					<Button label="查询" @click="getSaferList"></Button>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card">
				<DataTable :value="saferList" :paginator="true" class="p-datatable-gridlines" :rows="page_size" dataKey="id"
					:rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="loading1"
					:filters="filters1" responsiveLayout="scroll" :lazy="true" :totalRecords="totalCount" @page="onPage"
					:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">
					<template #empty>
						No safers found.
					</template>
					<template #loading>
						Loading safers data. Please wait.
					</template>

					<Column field="name" header="ID" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.id }}
						</template>
					</Column>

					<Column field="name" header="姓名" style="min-width:15rem">
						<template #body="{ data }">
							{{ data.username }}
						</template>
					</Column>

					<Column field="name" header="联系电话" style="min-width:15rem">
						<template #body="{ data }">
							{{ data.phone }}
						</template>
					</Column>

					<Column field="name" header="类型" style="min-width:5rem">
						<template #body="{ data }">
							{{ data.admin == 0 ? "企业用户" : "安全员" }}
						</template>
					</Column>

					<Column field="name" header="状态" style="min-width:5rem">
						<template #body="{ data }">
							<InputSwitch v-model="data.status" @input="onUserStatusChange(data.id)" />
						</template>
					</Column>
					<Column header="操作" style="min-width:20rem">
						<template #body="{ data }">
							<span class="p-input-icon-left">
								<Button label="编辑" class="p-button-success p-button-text mr-1"
									@click="onAddSafer('edit', data)" />
								<Button label="删除" class="p-button-danger p-button-text mr-1"
									@click="delSafer(data, $event)" />
							</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

	<Dialog :header="lodtitle" v-model:visible="displayAddSafer" :style="{ width: '30vw' }" :modal="true">
		<div class="col-12 p-fluid">
			<div class="field p-fluid">
				<label>角色</label>
				<div class="grid">
					<span class="p-float-label mr-3 mt-4 ml-2">
						<Dropdown id="roleName" :options="roleType" v-model="lodarr.admin" dataKey="id"
							:disabled="lodarr.id != ''" optionLabel="name" optionValue="id" @change="onRoleNameChange"
							placeholder="选择用户类型">
						</Dropdown>
					</span>

					<!-- 如果选择的是‘企业用户’，则可选择企业或更进一步选择场地等信息 -->
					<span class="p-float-label mr-3 mt-4 ml-2">
						<TreeSelect v-model="lodarr.permission" :options="enterpriseList" display="chip"
							placeholder="选择企业权限" selectionMode="checkbox" @change="onSaferEnterIdChange"></TreeSelect>
					</span>
					<!-- <span class="p-float-label mr-3 mt-4 ml-2" v-if="roleTypeSelected===2">
						<Dropdown id="saferField" :options="roleType" v-model="saferFieldId" dataKey="id"
							optionLabel="name" optionValue="id"></Dropdown>
						<label for="saferField">场地</label>
					</span> -->

					<!-- 如果选择的是‘安全员’，则可多选企业 -->
					<!-- <span class="p-float-label mr-3 mt-4 ml-2" v-if="roleTypeSelected===1">
						<MultiSelect  id="saferEnterList" v-model="selectedCities1" :options="cities" optionLabel="name"/>
						<label for="saferEnterList">企业</label>
					</span> -->
				</div>
			</div>

			<div class="field p-fluid">
				<label for="saferName">姓名</label>
				<InputText id="saferName" type="text" class="p-error" v-model="lodarr.name"
					aria-describedby="saferName-help" />
				<small id="saferName-help" class="p-error" v-show="addSaferNameError">请输入正确的姓名，由中文或者英文组成</small>
			</div>
			<div class="field p-fluid">
				<label for="saferPhone">电话</label>
				<InputText id="saferPhone" type="text" class="p-error" v-model="lodarr.phone"
					:disabled="this.lodarr.id != ''" aria-describedby="saferPhone-help" />
				<small id="saferPhone-help" class="p-error" v-show="addSaferPhoneError">请输入正确的电话</small>
			</div>
			<div class="field p-fluid">
				<label for="saferPwd">密码</label>
				<Password id="saferPwd" class="p-error" aria-describedby="saferPwd-help" v-model="lodarr.password"
					toggleMask></Password>
				<small id="saferPwd-help" class="p-error" v-show="addSaferPwdError">请输入超过6位密码</small>
			</div>


		</div>
		<template #footer>
			<Button label="取消" icon="pi pi-times" @click="displayAddSafer = false" class="p-button-text" />
			<Button label="确定" icon="pi pi-check" @click="doAddSafer" autofocus />
		</template>
	</Dialog>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import CustomerService from "../service/CustomerService";
import ProductService from '../service/ProductService';
export default {
	data() {
		return {
			customer1: null,
			customer2: null,
			customer3: null,
			filters1: null,
			filters2: {},
			loading1: true,
			loading2: true,
			idFrozen: false,
			displayAddSafer: false,
			products: null,
			expandedRows: [],
			statuses: [
				'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
			],
			breadcrumbHome: { icon: 'pi pi-home', label: ' James Butt', to: '#' },
			breadcrumbItems: [
				{ label: '' }
			],

			//----------------------------------------
			totalCount: 0, // 数据总数
			page_index: 1, // 当前页数
			page_size: 10, // 每页条数
			saferList: null,
			roleTypeSelected: null,
			roleType: [
				{ id: 1, name: "安全员" },
				{ id: 0, name: "企业用户" }
			],
			roleList: [], // 部门
			enterpriseList: [], // 企业
			enterpriseSelected: null,
			enterList: [], // 场地
			regionList: [], // 区域
			positionList: [], // 位置
			tungList: [], // 栋舍
			lodarr: {
				id: "",
				role_id: "", // 部门id
				enterprise_id: "", // 企业id
				enterprise_partition_id: "", // 场地id
				region_id: "", // 区域id
				position_id: "", // 位置id
				tung_id: "", // 栋舍
				name: "",
				phone: "",
				password: "",
				types: false,
				bath_code: "",
				gender: "",
				height: "",
				bath_type: "",
				archive_check: "",
			},
			searchContent: '',
			//----------------------------------------

			selectedCities1: null,
			cities: [
				{ name: 'New York', code: 'NY' },
				{ name: 'Rome', code: 'RM' },
				{ name: 'London', code: 'LDN' },
				{ name: 'Istanbul', code: 'IST' },
				{ name: 'Paris', code: 'PRS' }
			],
			addSaferNameError: false,
			addSaferPhoneError: false,
			addSaferPwdError: false,
			saferEnterId: null,
		}
	},
	customerService: null,
	productService: null,
	watch: {
		// roleTypeSelected: function (newVal, oldVal) {
		// 	console.log('roleTypeSelected:' + oldVal)
		// 	console.log('roleTypeSelected:' + newVal)
		// },
		// saferEnterId: function (newVal, oldVal){
		// 	console.log('saferEnterId old')
		// 	console.log(oldVal)
		// 	console.log('saferEnterId new')
		// 	console.log(newVal)

		// 	saferEnterId = oldVal;
		// }
	},
	created() {
		this.customerService = new CustomerService();
		this.productService = new ProductService();
		this.initFilters1();
	},
	mounted() {
		this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
		this.customerService.getCustomersLarge().then(data => {
			this.customer1 = data;
			this.loading1 = false;
			this.customer1.forEach(customer => customer.date = new Date(customer.date));
		});
		this.customerService.getCustomersLarge().then(data => this.customer2 = data);
		this.customerService.getCustomersMedium().then(data => this.customer3 = data);
		this.loading2 = false;

		this.getSaferList();
		//this.getRoleList();
		this.getEnterTree();
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},
		errorMessage(msg) {
			this.$toast.add({ severity: 'error', summary: '发生错误', detail: msg, life: 2000 });
		},
		goodMessage() {
			this.$toast.add({ severity: 'success', summary: '操作成功', life: 2000 });
		},

		onRoleNameChange(e) {
			console.log(e)
		},

		onSaferEnterIdChange(e) {
			console.log(e)
		},

		onPage(event){
			this.page_index = event.page + 1;
			this.getSaferList();
		},

		// 获取全部企业
		getEnterTree() {
			this.$http("/api/admin/enterprise/tree").then((res) => {
				if (res.code == 200) {
					this.enterpriseList.length = 0;
					this.enterpriseList = res.data.map((e) => {
						var n = new Object();
						n.key = e.value;
						n.label = e.text;
						n.data = e.value;
						n.icon = 'pi pi-fw pi-building';
						n.selectable = true;
						n.children = e.children.map(ee => {
							var nn = new Object();
							nn.key = ee.value;
							nn.label = ee.text;
							nn.data = ee.value;
							nn.icon = 'pi pi-fw pi-map-marker';
							nn.selectable = true;
							return nn;
						});
						return n;
					});
				}
			})
		},


		// 获取全部部门
		getRoleList() {
			this.$http("/api/admin/role/indexx", {
				enterprise_id:
					JSON.parse(window.localStorage.getItem('userinfo')).type == "ad"
						? ""
						: JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
			}).then((res) => {
				if (res.code == 200) {
					this.roleList = res.data;
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg , life: 3000 });
				}
			});
		},

		getSaferList() {
			this.$http('/api/admin/user/index', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				code: this.searchContent,
				role_id: '',
				page: this.page_index,
				page_size: this.page_size
			}).then(res => {
				if (res.code == 200) {
					res.data.data.map(e => {
						e.status = e.status == 0
					})
					this.saferList = res.data.data
					this.totalCount = res.data.total
					this.per_page = res.data.per_page
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg, life: 3000  });
				}
			})
		},

		getPermission(id) {
			return this.$http('/api/admin/user/permission', {
				user_id: id,
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					console.log(res.data)
					return res.data;
				} else {
					this.$toast.add({ severity: 'error', summary: res.msg, life: 3000  });
					return null;
				}
			})
		},

		async onAddSafer(type, item) {
			console.log(item);
			if (type == "add") {
				this.lodarr = {
					id: "",
					role_id: "", // 部门id
					enterprise_id: "", // 企业id
					enterprise_partition_id: "", // 场地id
					region_id: "", // 区域id
					position_id: "", // 位置id
					tung_id: "", // 栋舍
					name: "",
					phone: "",
					password: "",
					types: false,
					bath_code: "",
					gender: "",
					height: "",
					bath_type: "",
					archive_check: "",
					permission: "",
					admin: -1,
				};
				this.lodtitle = "新增管理员";
			} else {
				// 请求企业树状列表
				var p = await this.getPermission(item.id);

				this.lodarr = {
					id: item.id,
					phone: item.phone,
					name: item.username,
					password: item.password,
					role_id: item.role_id,
					height: item.height,
					sex: item.sex,
					enterprise_id: item.enterprise_id,
					enterprise_partition_id: item.enterprise_partition_id,
					region_id: item.region_id,
					position_id: item.position_id,
					tung_id: item.tung_id,
					types: item.types == 1,
					permission: p,
					admin: item.admin,
				};
				this.lodtitle = "编辑管理员";
			}

			this.displayAddSafer = true;
		},
		doAddSafer() {
			if (this.lodarr.id == '') {
				this.$http("/api/admin/user/create", {
					username: this.lodarr.name,
					password: this.lodarr.password,
					phone: this.lodarr.phone,
					tree_ids: this.lodarr.permission,
					admin: this.lodarr.admin,
					//id: this.lodarr.id,
				}).then((res) => {
					if (res.code == 200) {
						this.goodMessage();
						this.displayAddSafer = false;
						this.getSaferList();
					} else {
						this.errorMessage(res.msg)
					}
				});
			}
			else {
				if (this.lodarr.password != '') {
					this.$http('/api/admin/user/pwd', {
						id: this.lodarr.id,
						new_pwd: this.lodarr.password,
					}).then(res => {
						if (res.code != 200) {
							this.errorMessage(res.msg);
						}
					})
				}

				this.$http("/api/admin/user/modify", {
					username: this.lodarr.name,
					tree_ids: this.lodarr.permission,
					id: this.lodarr.id,
				}).then((res) => {
					if (res.code == 200) {
						this.goodMessage();
						this.displayAddSafer = false;
						this.getSaferList();
					} else {
						this.errorMessage(res.msg);
					}
				});
			}
		},
		delSafer(item, event) {
			this.$confirm.require({
				message: '确定要删除这个安全员吗',
				target: event.currentTarget,
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.$http("/api/admin/user/del", {
						id: item.id
					}).then((res) => {
						if (res.code == 200) {
							this.goodMessage();
							this.getSaferList();
						} else {
							this.errorMessage(res.msg);
						}
					});
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},

		// 启用禁用
		onUserStatusChange(id) {
			this.$http("/api/admin/user/enable", {
				id,
			}).then((res) => {
				if (res.code == 200) {
					this.goodMessage();
				} else {
					this.errorMessage(res.msg);
				}
			});
		},

		onRowSelect(event) {
			this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		onRowUnselect(event) {
			this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},
		removeCompany(id) {
			console.print('remove:' + id);
		},
		editCompany(id) {
			console.print('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	}
}
</script>

<style lang="scss" scoped>
.p-treeselect {
	width: 20rem;
}
</style>